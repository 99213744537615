import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "jasonDinsmore",
  "categories": ["rails", "ruby"],
  "date": "2019-11-20",
  "path": "/blog/10-New-Things-in-Active-Record",
  "summary": "Let's take a look at 10 new additions to Active Record in Rails 6!",
  "title": "10 New Things in Active Record",
  "image": "./ogp.png",
  "seo": {
    "og": {
      "type": "article",
      "title": "10 New Things in Active Record",
      "description": "Let's take a look at 10 new additions to Active Record in Rails 6!"
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia",
      "creator": "@dinjas"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In this post, we will take a look at 10 new additions to Active Record in Rails 6. For each addition, I'll include a link to the PR the feature was introduced in, a link to the author's GitHub profile, and a brief description of what the feature provides.`}</p>
    <p>{`We've got a lot to cover, so let's get going!`}</p>
    <h2>{`1. `}<inlineCode parentName="h2">{`rails db:prepare`}</inlineCode></h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/35768"
      }}>{`PR 35678`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/robertomiranda"
      }}>{`@robertomiranda`}</a></h3>
    <p>{`When this rake task is run, if the database exists, it runs any pending migrations. If a database does not exist, it runs the `}<inlineCode parentName="p">{`db:setup`}</inlineCode>{` rake task.`}</p>
    <p>{`This feature was designed to be idempotent, allowing it to be run over and over again until it completes successfully.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`rake db:prepare`}</inlineCode>{` task functions like this:`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "650px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "82.82208588957054%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAGQABAQEAAwAAAAAAAAAAAAAAAAMBAgQF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAEDAv/aAAwDAQACEAMQAAAB9zJdhPUgU4hmQnH/xAAbEAACAgMBAAAAAAAAAAAAAAAAARAiAgMRMf/aAAgBAQABBQKrMUlGtWOOH4f/xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BI//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABkQAAIDAQAAAAAAAAAAAAAAAAEgABEhMv/aAAgBAQAGPwLKMwJ2n//EABwQAAICAwEBAAAAAAAAAAAAAAABETEQIWFB8P/aAAgBAQABPyFQNdEEvpw5NK8HWnHT5JYsFR//2gAMAwEAAgADAAAAEHPvwf/EABwRAAEDBQAAAAAAAAAAAAAAAAEAEDERIVGx0f/aAAgBAwEBPxCLBUZ3xGC3/8QAGREAAgMBAAAAAAAAAAAAAAAAABEBECEx/9oACAECAQE/EFrGTyv/xAAcEAEAAwACAwAAAAAAAAAAAAABABExECFBccH/2gAIAQEAAT8QVbCdENkcRt1DeFVTFonn1CrLLlLqFemTvE+k/9k=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "rake db:prepare flowchart",
        "title": "rake db:prepare flowchart",
        "src": "/static/a4b35d326897597a315f2cd6e4843251/6aca1/rake-db-prepare.jpg",
        "srcSet": ["/static/a4b35d326897597a315f2cd6e4843251/d2f63/rake-db-prepare.jpg 163w", "/static/a4b35d326897597a315f2cd6e4843251/c989d/rake-db-prepare.jpg 325w", "/static/a4b35d326897597a315f2cd6e4843251/6aca1/rake-db-prepare.jpg 650w", "/static/a4b35d326897597a315f2cd6e4843251/ad059/rake-db-prepare.jpg 758w"],
        "sizes": "(max-width: 650px) 100vw, 650px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p>{`If you're not familiar with it, the `}<inlineCode parentName="p">{`db:setup`}</inlineCode>{` rake task:`}</p>
    <ul>
      <li parentName="ul">{`Creates the database`}</li>
      <li parentName="ul">{`Loads the `}<inlineCode parentName="li">{`schema.rb`}</inlineCode>{` or `}<inlineCode parentName="li">{`structure.sql`}</inlineCode>{` file (whichever your application is configured to use)`}</li>
      <li parentName="ul">{`Runs the `}<inlineCode parentName="li">{`db:seed`}</inlineCode>{` task, which executes the code in the `}<inlineCode parentName="li">{`db/seeds.rb`}</inlineCode>{` file`}</li>
    </ul>
    <h2>{`2. `}<inlineCode parentName="h2">{`rails db:seed:replant`}</inlineCode></h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/34779"
      }}>{`PR 34779`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/bogdanvlviv"
      }}>{`@bogdanvlviv`}</a></h3>
    <p>{`This new `}<inlineCode parentName="p">{`db:seed:replant`}</inlineCode>{` rake task does two things:`}</p>
    <ul>
      <li parentName="ul">{`Runs `}<inlineCode parentName="li">{`truncate`}</inlineCode>{` on all tables managed by ActiveRecord in the Rails environment the rake task is being run under. (Note that `}<inlineCode parentName="li">{`truncate`}</inlineCode>{` deletes all of the data in the table, but does not reset the table's auto-increment (ID) counter)`}</li>
      <li parentName="ul">{`Runs the `}<inlineCode parentName="li">{`db:seed`}</inlineCode>{` Rails rake task to populate seed data`}</li>
    </ul>
    <h2>{`3. Automatic Database Switching`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/35073"
      }}>{`PR 35073`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/eileencodes"
      }}>{`@eileencodes`}</a></h3>
    <p>{`Rails 6 provides a framework for auto-routing incoming requests to either the primary database connection, or a read replica.`}</p>
    <p>{`By default, this new functionality allows your app to automatically route read requests (`}<inlineCode parentName="p">{`GET`}</inlineCode>{`, `}<inlineCode parentName="p">{`HEAD`}</inlineCode>{`) to a read-relica database if it has been at least 2 seconds since the last write request (any request that is `}<em parentName="p">{`not`}</em>{` a `}<inlineCode parentName="p">{`GET`}</inlineCode>{` or `}<inlineCode parentName="p">{`HEAD`}</inlineCode>{` request) was made.`}</p>
    <p>{`The logic that specifies when a read request should be routed to a replica is specified in a resolver class, `}<inlineCode parentName="p">{`ActiveRecord::Middleware::DatabaseSelector::Resolver`}</inlineCode>{` by default, which you would override if you wanted custom behavior.`}</p>
    <p>{`The middleware also provides a session class, `}<inlineCode parentName="p">{`ActiveRecord::Middleware::DatabaseSelector::Resolver::Session`}</inlineCode>{` that is tasked with keeping track of when the last write request was made. Like the resolver, this class can also be overridden.`}</p>
    <p>{`To enable the default behavior, you would add the following configuration options to one of your app's environment files - `}<inlineCode parentName="p">{`config/environments/production.rb`}</inlineCode>{` for example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`active_record`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`database_selector `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` delay`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2.`}</span>{`seconds `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`active_record`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`database_resolver `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` 
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ActiveRecord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Middleware`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`DatabaseSelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Resolver`}</span>{`
config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`active_record`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`database_operations `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` 
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ActiveRecord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Middleware`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`DatabaseSelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Resolver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Session`}</span></code></pre></div>
    <p>{`If you decide to override the default functionality, you can use these configuration options to specify the delay you'd like to use, the name of your custom resolver class, and the name of your custom session class, both of which should be descendants of the default classes.`}</p>
    <h2>{`4. Negative Scopes for Enums`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/35381"
      }}>{`PR 35381`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/dhh"
      }}>{`@dhh`}</a></h3>
    <p>{`While `}<a parentName="p" {...{
        "href": "https://rubydocs.org/d/rails-6-0-0/classes/ActiveRecord/Enum.html"
      }}><inlineCode parentName="a">{`enum`}</inlineCode></a>{` has traditionally provided scopes to find items by their enum value, it has not provided scopes to find items not matching a specific enum value.`}</p>
    <p>{`For example, given a `}<inlineCode parentName="p">{`Post`}</inlineCode>{` model for a blog, with an enum on the `}<inlineCode parentName="p">{`status`}</inlineCode>{` field:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`enum status `}<span parentName="code" {...{
            "className": "token string"
          }}>{`%i(draft published archived)`}</span></code></pre></div>
    <p>{`The following scopes have been provided automatically for some time:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`scope `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:draft`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` where`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
scope `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:published`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` where`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
scope `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:archived`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` where`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now, the following negative scopes are also available:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`scope `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:not_draft`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` where`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`not`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
scope `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:not_published`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` where`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`not`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
scope `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:not_archived`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` where`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`not`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This makes it easy, for example, to find unpublished posts:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`Post`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`not_published`}</code></pre></div>
    <h2>{`5. `}<inlineCode parentName="h2">{`#extract_associated`}</inlineCode></h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/35784"
      }}>{`PR 35784`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/dhh"
      }}>{`@dhh`}</a></h3>
    <p>{`The new `}<a parentName="p" {...{
        "href": "https://rubydocs.org/d/rails-6-0-0/classes/ActiveRecord/QueryMethods.html#method-i-extract_associated"
      }}><inlineCode parentName="a">{`#extract_associated`}</inlineCode></a>{` method is literally just a shorthand for `}<a parentName="p" {...{
        "href": "https://rubydocs.org/d/rails-6-0-0/classes/ActiveRecord/QueryMethods.html#method-i-preload"
      }}><inlineCode parentName="a">{`preload`}</inlineCode></a>{` plus `}<a parentName="p" {...{
        "href": "https://rubydocs.org/d/ruby-2-6-5/classes/Enumerable.html#method-i-collect"
      }}><inlineCode parentName="a">{`map/collect`}</inlineCode></a>{`.`}</p>
    <p>{`Here's the source code of the method:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`extract_associated`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`association`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  preload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`association`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`collect`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`association`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Be aware that `}<inlineCode parentName="p">{`preload`}</inlineCode>{` does not allow you to specify conditions on the association being "preloaded". You'd want to use a different eager-loading mechanism for that, likely `}<inlineCode parentName="p">{`includes`}</inlineCode>{`, `}<inlineCode parentName="p">{`eager_load`}</inlineCode>{` or `}<inlineCode parentName="p">{`joins`}</inlineCode>{`.`}</p>
    <p>{`Usage of `}<inlineCode parentName="p">{`#extract_associated`}</inlineCode>{` might look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`commented_posts `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`comments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`extract_associated`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:post`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2>{`6. `}<inlineCode parentName="h2">{`#annotate`}</inlineCode></h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/35617"
      }}>{`PR 35617`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/mattyoho"
      }}>{`@mattyoho`}</a></h3>
    <p>{`This is a nifty addition that could be used to add useful information to your application's log files. The `}<a parentName="p" {...{
        "href": "https://rubydocs.org/d/rails-6-0-0/classes/ActiveRecord/QueryMethods.html#method-i-annotate"
      }}><inlineCode parentName="a">{`#annotate`}</inlineCode></a>{` method provides a mechanism to embed comments into the SQL generated by ActiveRecord queries. As an added benefit, the comments it generates could be completely dynamic.`}</p>
    <p>{`Inserting `}<inlineCode parentName="p">{`annotate`}</inlineCode>{` into your query chain like below:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`User`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`annotate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'there can be only one!'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find_by`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`highlander`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Would generate the following SQL:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sql"
    }}><pre parentName="div" {...{
        "className": "language-sql"
      }}><code parentName="pre" {...{
          "className": "language-sql"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`SELECT`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"users"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`FROM`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"users"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WHERE`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"users"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"highlander"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` ? `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* there can be only one! */`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`LIMIT`}</span>{` ? `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"highlander"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"LIMIT"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <h2>{`7. `}<inlineCode parentName="h2">{`#touch_all`}</inlineCode></h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/31513"
      }}>{`PR 31513`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/fatkodima"
      }}>{`@fatkodima`}</a></h3>
    <p>{`Another `}<a parentName="p" {...{
        "href": "https://rubydocs.org/d/rails-6-0-0/classes/ActiveRecord/Relation.html"
      }}><inlineCode parentName="a">{`ActiveRecord::Relation`}</inlineCode></a>{` method, `}<a parentName="p" {...{
        "href": "https://rubydocs.org/d/rails-6-0-0/classes/ActiveRecord/Relation.html#method-i-touch_all"
      }}><inlineCode parentName="a">{`#touch_all`}</inlineCode></a>{` touches all records in the current scope, updating their timestamps.`}</p>
    <p>{`You can pass an array of columns to touch, and optionally provide a time value to use. `}<inlineCode parentName="p">{`touch_all`}</inlineCode>{` defaults to the current time in whatever timezone the app's config has set for `}<inlineCode parentName="p">{`config.active_record.default_timezone`}</inlineCode>{` (the setting defaults to UTC).`}</p>
    <p>{`For example, to update the `}<inlineCode parentName="p">{`updated_at`}</inlineCode>{` field of all comments associated with a given blog post, `}<inlineCode parentName="p">{`@post`}</inlineCode>{`, you could:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token variable"
          }}>{`@post`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`comments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`touch_all`}</code></pre></div>
    <p>{`To update a given field on the comments, say `}<inlineCode parentName="p">{`:reviewed_at`}</inlineCode>{`, you would provide the column name:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token variable"
          }}>{`@post`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`comments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`touch_all`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:reviewed_at`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`And, to specify a time value, you would:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token variable"
          }}>{`@post`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`comments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`touch_all`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:reviewed_at`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` time`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` the_time`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2>{`8. `}<inlineCode parentName="h2">{`#destroy_by`}</inlineCode>{` and `}<inlineCode parentName="h2">{`#delete_by`}</inlineCode></h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/35316"
      }}>{`PR 35316`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/abhaynikam"
      }}>{`@abhaynikam`}</a></h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://rubydocs.org/d/rails-6-0-0/classes/ActiveRecord/Relation.html#method-i-destroy_by"
      }}><inlineCode parentName="a">{`destroy_by`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https://rubydocs.org/d/rails-6-0-0/classes/ActiveRecord/Relation.html#method-i-delete_by"
      }}><inlineCode parentName="a">{`delete_by`}</inlineCode></a>{` methods are intended to provide symmetry (`}<a parentName="p" {...{
        "href": "https://github.com/rails/rails/issues/35304#issuecomment-464489289"
      }}>{`"in spirit"`}</a>{`) with  `}<inlineCode parentName="p">{`ActiveRecord`}</inlineCode>{`'s `}<a parentName="p" {...{
        "href": "https://rubydocs.org/d/rails-6-0-0/classes/ActiveRecord/FinderMethods.html#method-i-find_by"
      }}><inlineCode parentName="a">{`find_by`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https://rubydocs.org/d/rails-6-0-0/classes/ActiveRecord/Relation.html#method-i-find_or_create_by"
      }}><inlineCode parentName="a">{`find_or_create_by`}</inlineCode></a>{` methods.`}</p>
    <p>{`I believe there is an important distinction you should be aware of. `}<inlineCode parentName="p">{`find_by`}</inlineCode>{` returns one record, or `}<inlineCode parentName="p">{`nil`}</inlineCode>{`, whereas `}<inlineCode parentName="p">{`destroy_by`}</inlineCode>{` and `}<inlineCode parentName="p">{`delete_by`}</inlineCode>{` will match on entire collections of records.`}</p>
    <p>{`Using `}<inlineCode parentName="p">{`find_by`}</inlineCode>{` like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find_by`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Generates the following SQL:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sql"
    }}><pre parentName="div" {...{
        "className": "language-sql"
      }}><code parentName="pre" {...{
          "className": "language-sql"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`SELECT`}</span>{`  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"users"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`FROM`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"users"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WHERE`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"users"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` $`}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`LIMIT`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`Whereas, using `}<inlineCode parentName="p">{`delete_by`}</inlineCode>{` with the same parameters:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`delete_by`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Results in the following SQL:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sql"
    }}><pre parentName="div" {...{
        "className": "language-sql"
      }}><code parentName="pre" {...{
          "className": "language-sql"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`DELETE`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`FROM`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"users"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WHERE`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"users"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` ?  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`Definitely something to keep in mind when using these methods!`}</p>
    <p>{`Also, it's worth noting that there are no bang ( `}<inlineCode parentName="p">{`!`}</inlineCode>{`) versions of the `}<inlineCode parentName="p">{`delete_by/destroy_by`}</inlineCode>{` methods.`}</p>
    <h2>{`9. Endless Ranges in `}<inlineCode parentName="h2">{`#where`}</inlineCode></h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/34906"
      }}>{`PR 34906`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/gregnavis"
      }}>{`@gregnavis`}</a></h3>
    <p>{`Ruby 2.6 introduced infinite ranges. This new feature lets you use them in Rails' `}<inlineCode parentName="p">{`#where`}</inlineCode>{` clauses.`}</p>
    <p>{`For example, when trying to find a Post with more than 10 comments (contrived example, I know).`}</p>
    <p>{`Before you would have to use SQL like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`Post`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`where`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'num_comments > ?'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Now you can use syntax that is more idiomatic Ruby:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`where`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`num_comments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`10.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2>{`10. Implicit Ordering`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/34480"
      }}>{`PR 34480`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/tekin"
      }}>{`@tekin`}</a></h3>
    <p>{`This feature makes implicit ordering configurable for a database table. Rails  implicitly orders results by the table's primary key, which can give surprising results when the primary key is something that isn't an auto-incrementing  integer (like a UUID).`}</p>
    <p>{`Setting a table's implicit order column allows you to specify a default order,  without using a default scope, meaning that you don't need to use `}<inlineCode parentName="p">{`reorder`}</inlineCode>{` in  your code to change the order downstream, you can use `}<inlineCode parentName="p">{`order`}</inlineCode>{` (assuming you have not already specified explicit ordering earlier in the query chain).`}</p>
    <p>{`For example, if you declare an implicit ordering on your `}<inlineCode parentName="p">{`Post`}</inlineCode>{` table:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Post`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ActiveRecord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Base`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`implicit_order_column `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'title'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Be aware, that if you declare implicit ordering on a column that does not ensure unique values, your results may not be what you expect.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      